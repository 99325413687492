/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/styles/global.css"
import postscribe from "postscribe"
import React from 'react';
import Layout from './src/components/layout';

/** Globals */
window.postscribe = postscribe;
window.activeBranch = `${process.env.GATSBY_NETLIFY_BRANCH}`;

/**
 * Called when the initial (but not subsequent) render of Gatsby App is done on the client.
 */
export const onInitialClientRender = () => { }

/**
 * Called when the Gatsby browser runtime first starts.
 */
export const onClientEntry = () => { }

/* Wraps each page element in the Layout component */
export const wrapPageElement = ({ element, props }) => {
  return (<Layout {...props}>{element}</Layout>)
}
