/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles";

import BottomNav from "../components/bottom-nav"

const styles = theme => ({
  root: {
    minHeight: '100vh',
  },
});

const Layout = ({ classes, children, location }) => {
  let path = '';
  if (typeof(location) !== 'undefined') {
    path = location.pathname;
  }
  return (
      <>
      <main className={classes.root}>
          {children}
        </main>
        <footer>
          <BottomNav />
        </footer>
      </>
    );
  }

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Layout);
