import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "gatsby"
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import grey from '@material-ui/core/colors/grey';

import logo from '../images/favicon-dark.ico'

const styles = theme => ({
  root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {},
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  navLinks: {
    '& a': {
      color: grey[600],
      textDecoration: `none`,
      marginRight: 20,
      '&:hover': {
        textDecoration: 'underline'
      }  
    },
    '& a:last-child': {
      marginRight: 0
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  logo: {
    '& a': {
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center'
    },
    
    margin: 0,
    
  },
  logoImg: {
    margin: 0,
    marginRight: 6
  },
});

class BottomNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMoreAnchorEl: null,
      selectedIndex: 0,
    };
  }
  
  handleMobileMenuOpen = event => {
    this.setState({mobileMoreAnchorEl: event.currentTarget});
  };

  handleMobileMenuClose = event => {
    this.setState({mobileMoreAnchorEl: null});
  }

  handleMobileMenuItemClick = (event, index) => {
    this.setState({
      selectedIndex: index,
      mobileMoreAnchorEl: null
    });
  }

  render() {
    const { mobileMoreAnchorEl } = this.state;
    const { classes } = this.props;
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const navLinks = [
      {
        title: 'Contact',
        href: '/contact/',
        internal: true,
      },
      {
        title: 'Privacy',
        href: '/privacy/',
        internal: true,
      },
      {
        title: 'Terms',
        href: '/terms/',
        internal: true,
      },
      {
        title: 'Advertise',
        href: '/advertise/',
        internal: false,
      },
      {
        title: 'DMCA',
        href: '/dmca/',
        internal: true,
      }
    ];

    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMobileMenuClose}
      >
      {navLinks.map((link, index) => (
        <MenuItem
          key={index}
          disabled={index === this.state.selectedIndex}
          onClick={event => this.handleMobileMenuItemClick(event, index)}
          selected={index === this.state.selectedIndex}>
          {link.internal ? (<Link to={link.href}>{link.title}</Link>) : (<a href={link.href}>{link.title}</a>)}
        </MenuItem>
      ))}
      </Menu>
    );

    return (
      <div className={classes.root}>
        <AppBar position="fixed" color="default" className={classes.appBar}>
          <Toolbar variant="dense">
            <h3 className={classes.logo}>
              <Link to='/' className={classes.logo}>
                <img className={classes.logoImg} src={`${logo}`} width='24' height='24' alt='logo'/>
                <span style={{color: '#000000'}}>Search</span>
                <span style={{color:'#00c1ef'}}>Swift</span>
              </Link>
          </h3>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <div className={classes.navLinks}>
                {navLinks.map((link, index) => (
                  <React.Fragment key={index}>
                  {link.internal ? (<Link to={link.href}>{link.title}</Link>) :(<a href={link.href}>{link.title}</a>)}
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton 
                className={classes.menuButton}
                color="inherit"
                aria-label="Menu"
                onClick={this.handleMobileMenuOpen}
              >
                <MenuIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
      </div>
    );
  }
}

BottomNav.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BottomNav);